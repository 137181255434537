import React, { useEffect } from 'react';
import { useState } from 'react';
import { useUser } from '../../hooks/getUser';
import './statistics.style.css';
import { BarChart } from '@mui/x-charts/BarChart';
import { useOrders } from '../../hooks/getOrders';
import { OverviewBudget } from './overview-budget';
import { ResponsiveChartContainer } from '@mui/x-charts';

const xLabels = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];
const pData = [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0];
const uData = [0, 0, 0, 0, 0, 0, 0,0,0,0,0,0];

export const Statistics = () => {
    const user = useUser();
    const { isLoading, getOrders } = useOrders();
    const [totalSales, setTotalSales] = useState(0);
    const [earned, setEarned] = useState(0);

    const onSortOrders = (getOrders) => {
        getOrders.map((value)=>{
            console.log(value);
           const math = value.date.split('-');
           for (let i = 0; i < xLabels.length; i++) {
               if(math[1] == i+1) {
                   if(value.status === 0) {
                       pData[i] += 1;
                       return;
                   }
                   console.log(value);
                   uData[i] += 1;
               }
           }
        });
    };

    const onTotalSales = (getOrders) => {
        getOrders.map((value) => {
            if(value.status === 2) {
                setTotalSales((item)=> item + Number(value.amount));
                return;
            }
        });
    };

    const onEarned = (getOrders) => {
        getOrders.map((value) => {
            if(value.status === 2) {
                setEarned((item)=> item + Number(value?.balance.count));
                return;
            }
        });
    };

    useEffect(() => {
        if(!isLoading){
            onSortOrders(getOrders);
            onTotalSales(getOrders);
            onEarned(getOrders);
        }
        return () => {
            setTotalSales(0);
            setEarned(0);
            pData.fill(0);
            uData.fill(0);
        }
    },[isLoading])
    return (
        <>
            <div className='dashboard-content-header'>
                <h2>Статистика</h2>
            </div>

                    <BarChart
                        className="chart"
                        height={300}
                        series={[
                            { data: pData, label: 'Заявки', id: 'pvId', stack: 'stack1' },
                            { data: uData, label: 'Оплачены', id: 'uvId', stack: 'stack1' },
                        ]}
                        xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    />


            <div className="badge">
                <OverviewBudget
                    difference={12}
                    positive
                    sx={{ width: '300px' }}
                    value={`${totalSales} Руб.`}
                    title="Продаж"
                />
                <OverviewBudget
                    difference={12}
                    positive
                    sx={{ width: '300px' }}
                    value={`${earned} Руб.`}
                    title="Заработано"
                />
            </div>
        </>
    )
};
