import React from "react";
import { Link } from 'react-router-dom';
import './styles.css';

const SideBarItem = ({ item, active }) => {
    return (
        <Link
            to={item.path}
            className={active ? 'sidebar-item-active' : 'sidebar-item'}
        >
            {item.icon}
            <span className='sidebar-item-label'>{item.title}</span>
        </Link>
    )
}
export default SideBarItem;
