import React from 'react';
import './input.style.css';

export const Input = (props) => {
    const {
        type = "text" || "email" || "password",
        onChange,
        onBlur = () => {},
        value = "",
        error = "",
        title = "",
        styleTitle = "",
        styleInput = "",
        styleError = "",
    } = props;

    return (
        <div>
            <div className={`input__title ${styleTitle}`}>
                {title}
            </div>
            <input
                {...props}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className={`input ${styleInput}`}
            />
            <div className={`input__error ${styleError}`}>
                {error}
            </div>
        </div>
    );
}
