import { useContext, useEffect } from 'react';
import { AuthContext } from '../context';
import { _URL } from '../config';

export const useRewardSize = (merchID) => {
    const { merch, setMerch, user } = useContext(AuthContext);

    useEffect(() => {
        fetch(`${_URL}getRewardSize.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'uuid': user.uuid,
                    'merchID': merchID
                })
        })
            .then(item => item.json())
            .then(response => {
                setMerch(response);
            });
    }, [merchID]);

    return merch;
}
