import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import './styles.css';

function Table (props) {

    const {
        rows,
        onRows,
        onThead,
        isLoading
    } = props;

    const [search, setSearch] = useState([]);
    const [ordersLine, setOrdersLine] = useState([]);
    const [page, setPage] = useState(0);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        setOrdersLine(rows);
        setPagination(onPagination(rows, 10))
    }, [rows]);

    const __handleSearch = (event) => {
        const { value } = event.target;
        if (value !== '') {
            let search_results = ordersLine.filter((item) => {
                if (item.fio) {
                    return item.fio.toLowerCase().includes(value.toLowerCase())
                }

                return item.name.toLowerCase().includes(value.toLowerCase())
            });
            setSearch(onPagination(search_results, 10));
            return;
        }

        setPagination(onPagination(rows, 10))
    };

    const onPagination = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    };

    return(
        <>
            <div className="search">
                <TextField
                    className="search_input"
                    label="Поиск"
                    onChange={__handleSearch}
                    variant="outlined"
                />
                {search.length ? (<Close
                    className="search_button__close"
                    onClick={() => setSearch([])}
                />) : null}
            </div>
            <div className="tableBlock">
                <table>
                    <thead>
                    {onThead()}
                    </thead>
                    {onRows(search.length ? search : pagination, page)}
                </table>
            </div>
            <div className='dashboard-content-footer'>
                {pagination.map((item, index) => (
                    <span
                        key={index}
                        className={index === page ? 'active-pagination' : 'pagination'}
                        onClick={() => setPage(index)}
                    >
                        {index+1}
                    </span>
                )) }
            </div>

            {isLoading ? (
                <div className='dashboard-content-footer'>
                    <span className='empty-table'>Загрузка ...</span>
                </div>
            ) : null}
        </>
    )
}

export default Table;
