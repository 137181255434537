import React, { useState } from 'react';
import DoneIcon from '../../assets/icons/done.svg';
import CancelIcon from '../../assets/icons/cancel.svg';
import { useOrders } from '../../hooks/getOrders';
import { ModalView } from './Modal';
import Table from '../../components/Table/Table';
import '../styles.css';
import './style.css';

function Orders () {
    const { isLoading, getOrders } = useOrders();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState("");
    const onOpenModal = (order) => {
        setSelectedOrder((value) => {
            setModalOpen(true);
            return order;
        });
    }

    const TableRows = (rows, page) => {
        if (!rows.length) return null;

        return (
            <tbody>
                {rows[page]?.map((order) => (
                    <tr
                        key={order.uuid}
                        className="rowTable"
                        onClick={() => onOpenModal(order)}
                    >
                        <td><span>{order.uuid}</span></td>
                        <td><span>{order.date}</span></td>
                        <td>
                            <div>
                                {order.status === 2 ?
                                    <img
                                        src={DoneIcon}
                                        alt='paid-icon'
                                        className='dashboard-content-icon' />
                                    :  <img
                                        src={CancelIcon}
                                        alt='canceled-icon'
                                        className='dashboard-content-icon' />
                                }
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>{order.name}</span>
                            </div>
                        </td>
                        <td><span>{order.title}</span></td>
                        <td><span>{order.amount} руб.</span></td>
                    </tr>
                ))}
            </tbody>
        );
    };

    const TableHeader = () => {
        return (<tr>
                    <th>ID</th>
                    <th>Дата</th>
                    <th>Статус</th>
                    <th>Покупатель</th>
                    <th>Продукт</th>
                    <th>Сумма</th>
                </tr>);
    }

    return(
        <>
            <div className='dashboard-content-header'>
                <h2>Продажи</h2>
            </div>
            <ModalView
                visible={modalOpen}
                setVisible={setModalOpen}
                order={selectedOrder}
            />
            <Table
                rows = {getOrders}
                onRows = {TableRows}
                isLoading = {isLoading}
                onThead = {TableHeader}
            />
        </>
    )
}

export default Orders;
