import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react';
import React, { useCallback, useState } from 'react';
import { _URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export const ModalView = ({visible = false, setVisible, order }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const onDelete = useCallback(async (values) => {
        setIsLoading(true);
        await fetch(`${_URL}agents/delete.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    uuid: order.uuid
                })
        })
            .then(item => item.json())
            .then(response => {
                setVisible(false);
            }).finally(() =>  setIsLoading(false))
    }, [order]);
    return (
        <>
            <CModal backdrop="static" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader>
                    <CModalTitle>Удалить запись?</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {order.lastName} {order.name} {order.patronymic}
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Закрыть
                    </CButton>
                    <CButton color="primary"  onClick={() => onDelete()}>
                        {isLoading ? <CSpinner color="primary" style={{width: 20, height: 20, marginRight: 10}}/> : null}
                        Удалить
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}
