import React, { useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useDistrib } from '../../hooks/useDistrib';
import {  ModalView } from './Modal';
import '../styles.css';
import Table from '../../components/Table/Table';

const DistribLayout = () => {
    const { isLoading, getDistrib } = useDistrib();
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const [ordersLine, setOrdersLine] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState("");

    useEffect(() => {
        const formatRowsTable = getDistrib.map( (item) => {
            return ({
                        fio: `${item.lastName} ${item.name} ${item.patronymic}`,
                        ...item,
                    })
        });
        setOrdersLine(formatRowsTable);
    }, [getDistrib]);

    const onCount = (item) => {
        let summ = 0;
        item.map(value => {
            summ += Number(value.amount);
        })
        return summ;
    }

    const onShowDeleteModal = (values) => {
        setSelectedOrder(item => {
            setModalOpen(true);
            return item = values;
        });
    };

    const TableRows = (rows, page) => {
        if (!rows.length) return null;

        return (
            <tbody>
            {rows[page]?.map((order) => (
                <tr key={order.uuid}>
                    <td><span>{order.id}</span></td>
                    <td><span>{order.fio}</span></td>
                    <td><span>{order?.sale.length} шт.</span></td>
                    <td>
                        <span>
                            {onCount(order?.sale)} руб.
                        </span>
                    </td>
                    <td>
                        <span>{order.totalBalance || 0} руб.</span>
                    </td>

                    <td>
                        <span>{order.rewardSize ? order.rewardSize.value : 0 } руб</span>
                    </td>
                    <td>
                        <Button
                            onClick = {() => onShowDeleteModal(order)}
                        >
                            X
                        </Button>
                    </td>
                </tr>
            ))}
            </tbody>
        );
    };

    const TableHeader = () => {
        return (
            <tr>
                <th>ID</th>
                <th>ФИО</th>
                <th>кол. заказов</th>
                <th>заказов на сумму</th>
                <th>заработано</th>
                <th>ставка</th>
                <th></th>
            </tr>
        );
    }

    return(
        <>
            <div className='dashboard-content-header'>
                <h2>Дистрибьюторы</h2>
                <Button
                    onClick={() => navigate('addDisrib',{ relative: "path" })}
                >
                    Добавить
                </Button>
            </div>

            <Table
                rows = {ordersLine}
                onRows = {TableRows}
                isLoading = {isLoading}
                onThead = {TableHeader}
            />

            <ModalView
                visible={modalOpen}
                setVisible={setModalOpen}
                order={selectedOrder}
            />
        </>
    )
}

export default DistribLayout;
