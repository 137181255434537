import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useCallback, useState } from 'react';
import { _URL } from '../../config';
import { useFormik } from 'formik';
import { useProducts } from '../../hooks/getProducts';

export const ModalView = ({ visible = false, setVisible, user }) => {
    const [isLoadingURL, setIsLoading] = useState(false);
    const {getProducts} = useProducts();

    const onUpdate = useCallback(async values => {
        setIsLoading(true);
        console.log(values);
        console.log(user);
        await fetch(`${_URL}users/setReward.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    userUUID: user.uuid,
                    merchId: values.merchId,
                    value: values.value,
                })
        })
            .then(item => item.text())
            .then(response => {
                setVisible(false);
            }).finally(() => setIsLoading(false));
    }, [user]);

    const formik = useFormik({
        initialValues: {
            merchId: '',
            value: '',
        },
        onSubmit: (values) => onUpdate(values),
    });
    console.log(getProducts)

    const onSelectProduct = (event) => {
        const product = event.target.value;
        formik.values.merchId = product;
    };

    return (
            <CModal backdrop="static" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader>
                    <CModalTitle>{user.lastName} {user.name} {user.patronymic}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form onSubmit={formik.handleSubmit}>
                        ставка:
                        <input
                            type="text"
                            title="Ставка"
                            name="value"
                            onChange={formik.handleChange}
                            value={formik.values.value}
                        />

                        <br />
                        продукт:
                        <select onChange={onSelectProduct} name="deliveryMethod" className="select__delivery">
                            <option value="">Выберите продукт</option>
                            {getProducts.map(value => (
                                <option value={value.id}>{value.title}</option>
                            ))}
                        </select>
                    </form>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Закрыть
                    </CButton>
                    <CButton
                        color="primary"
                        onClick={() => formik.handleSubmit()}
                    >
                        {isLoadingURL ? <CSpinner type="submit" color="primary"
                                               style={{ width: 20, height: 20, marginRight: 10 }} /> : null}
                        Обновить
                    </CButton>
                </CModalFooter>
            </CModal>
    );
};
