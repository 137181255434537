import React, { useCallback, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";

import './login.styles.css';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { _URL } from '../../config';
import { AuthContext } from '../../context';
import { useAuth } from '../../hooks/getAuth';

export const Login = () => {
    const navigate = useNavigate();
    const { setAuth, setUser } = useContext(AuthContext);
    useAuth();

    const initialValues = {
        email: '',
        password: '',
    };

    const onValidate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'поле E-mail обязательное';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'не верный e-mail адрес';
        }
        return errors;
    };

    useEffect(() => {
        const uuid = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && uuid) {
            setAuth(true);
            navigate('/');
        }
    }, []);

    const onSend = useCallback(async (values) => {
        await fetch(`${_URL}/userAuth.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'login': values.email,
                    'password': values.password,
                })
        })
            .then(item => item.json())
            .then(response => {
                if(response.uuid) {
                    localStorage.setItem('token', response.token);
                    setAuth(true);
                    setUser(response);
                    navigate('/');
                }
            })
    }, []);

    return (
        <div className="login-page__container">
            <div className="login-page__container__form">
                <h1>Авторизация</h1>
                <Formik
                    initialValues={initialValues}
                    validate={values => onValidate(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        onSend(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Input
                                type="email"
                                title="e-mail"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={errors.email}
                            />
                            <Input
                                type="password"
                                title="Пароль"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={errors.password}
                            />
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                title="Войти"
                                view="primary"
                            >
                                Войти
                            </Button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
