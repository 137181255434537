import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';

const sidebar_menu = [
    {
        id: 1,
        icon: <DashboardOutlinedIcon/>,
        path: '/',
        title: 'Главная',
        permission: [1,2,3]
    },
    {
        id: 2,
        icon: <GroupOutlinedIcon/>,
        path: '/agents',
        title: 'Агенты',
        permission: [2]
    },

    {
        id: 3,
        icon: <PermIdentityOutlinedIcon/>,
        path: '/distrib',
        title: 'Дистрибьютор',
        permission: [2,3]
    },
    {
        id: 4,
        icon: <ShoppingBasketOutlinedIcon/>,
        path: '/orders',
        title: 'Продажи',
        permission: [1,2,3]
    },
    {
        id: 41,
        icon: <InsertChartIcon/>,
        path: '/statistics',
        title: 'Статистика',
        permission: [1,2,3]
    },
    {
        id: 5,
        icon: <ExtensionOutlinedIcon/>,
        path: '/products',
        title: 'Товары',
        permission: [2]
    },
    {
        id: 6,
        icon: <Groups2OutlinedIcon/>,
        path: '/users',
        title: 'Пользователи',
        permission: [2]
    },
    {
        id: 7,
        icon: <AccountBoxOutlinedIcon/>,
        path: '/profile',
        title: 'Мой профиль',
        permission: [1,2,3]
    }
]

export default sidebar_menu;
