import React, { useCallback, useContext } from 'react';
import './product.style.css';
import { useProfile } from '../../hooks/getProfile';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/Input';
import { Formik } from 'formik';
import { AuthContext } from '../../context';
import { _URL } from '../../config';
import './update.style.css';

export const PayProfile = () => {
    const { isLoading, getProfile } = useProfile();

    const navigate = useNavigate();

    const initialValues = isLoading ? {
        email: getProfile?.login,
        password: getProfile?.password ,
        lastName: getProfile?.lastName,
        firstName: getProfile?.name,
        patronymic: getProfile?.patronymic
    } : {}

    const onValidate = (values) => {
        const errors = {};
        if(!values.email) {
            errors.email = 'Это поле обязательное'
        }

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Введите корректный E-mail'
        }

        if(!values.password) {
            errors.password = 'Это поле обязательное'
        }

        if(!values.lastName) {
            errors.lastName = 'Это поле обязательное'
        }

        if(!values.firstName) {
            errors.firstName = 'Это поле обязательное'
        }

        if(!values.password && values.password.length < 5) {
            errors.password = 'Пароль должен содержать не менее 6 символов'
        }

        return errors;
    };

    const onSend = useCallback(async (values) => {
        await fetch(`${_URL}addDistrib.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'lastName': values.lastName,
                    'firstName': values.firstName,
                    'patronymic': values.patronymic,
                    'email': values.email,
                    'password': values.password,
                    'uuidAgent': values.uuidAgent
                })
        })
            .then(item => item.json())
            .then(response => {
                if(response.uuid) {
                    navigate('/disrib');
                }
            })
    }, []);

    if(isLoading) {
        return (<>Загрузка...</>);
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validate={(item) => onValidate(item)}
                onSubmit={(values, { setSubmitting }) => {
                    onSend(values);
                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => (
                    <div className='dashboard-content'>
                        <div className='dashboard-content-container'>
                            <div className='dashboard-content-header'>
                                <h2>Редактировать данные карты</h2>
                                <div>
                                    <Button
                                        onClick={() => navigate('/profile')}
                                    >
                                        Отмена
                                    </Button>
                                    {" "}
                                    <Button
                                        type="submit"
                                        title="Регистрация"
                                        view="primary"
                                    >
                                        Сохранить
                                    </Button>
                                </div>
                            </div>
                            <div className="profile__container">
                                <form onSubmit={handleSubmit}>
                                    <div className="profileUpdate">
                                        <Input
                                            type="text"
                                            title="Номер карты"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            error={errors.email}
                                            styleTitle="titleInput"
                                            styleInput="inputStyle"
                                        />
                                        <Input
                                            type="text"
                                            title="Наименование банка"
                                            name="firstName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            error={errors.firstName}
                                            styleTitle="titleInput"
                                            styleInput="inputStyle"
                                        />

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}
