import * as React from "react";
import './tabs.style.css';

export const TabSelector = (props) => {
    const {
        isActive,
        children,
        onClick,
    } = props;

    return(
    <button className={isActive ? "tabs-button tabs-button-active" : " tabs-button"}
        onClick={onClick}
    >
        {children}
    </button>
)};
