
const all_orders = [
    {
        id: "2",
        product: "Phenilxepin",
        price: "3900",
        url: 'https://phenilxepin.com/',
        pay: 'https://gordonlab.ru/orderForms/?user=123&id=2'
    },
];

/*
*  {
 id: "1",
 product: "Teroxolax",
 price: "3800",
 url: 'http://teroxolax.com/',
 pay: 'https://gordonlab.ru/orderForms/?user=123&id=1'
 },
* */

export default all_orders;
