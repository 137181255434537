import React, { useCallback, useContext } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import './addAgents.style.css';
import { _URL } from '../../config';
import { AuthContext } from '../../context';

export const AddAgents = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const initialValues = {
        email: '',
        password: '',
        lastName: '',
        firstName: '',
        patronymic: '',
        uuidAgent: user.uuid
    }

    const onValidate = (values) => {
        const errors = {};
        if(!values.email) {
            errors.email = 'Это поле обязательное'
        }

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Введите корректный E-mail'
        }

        if(!values.password) {
            errors.password = 'Это поле обязательное'
        }

        if(!values.lastName) {
            errors.lastName = 'Это поле обязательное'
        }

        if(!values.firstName) {
            errors.firstName = 'Это поле обязательное'
        }

        if(!values.password && values.password.length < 5) {
            errors.password = 'Пароль должен содержать не менее 6 символов'
        }

        return errors;
    };

    const onSend = useCallback(async (values) => {
        await fetch(`${_URL}/addAgents.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'lastName': values.lastName,
                    'firstName': values.firstName,
                    'patronymic': values.patronymic,
                    'email': values.email,
                    'password': values.password,
                    'uuidAgent': values.uuidAgent
                })
        })
            .then(item => item.json())
            .then(response => {
                if(response.uuid) {
                    navigate('/agents');
                }
            })
    }, []);

    return (
        <>
            <div className='dashboard-content-header'>
                <h2>Добавление агентов</h2>
                <Button
                    onClick={() => navigate('/agents')}
                >Отмена</Button>
            </div>
            <div className="register-page__container__form">
                    <Formik
                        initialValues={initialValues}
                        validate={(item) => onValidate(item)}
                        onSubmit={(values, { setSubmitting }) => {
                            onSend(values);
                            setSubmitting(false);
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Input
                                    type="email"
                                    title="e-mail"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={errors.email}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />
                                <Input
                                    type="text"
                                    title="Имя:"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    error={errors.firstName}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />

                                <Input
                                    type="text"
                                    title="Фамилия:"
                                    name="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    error={errors.lastName}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />

                                <Input
                                    type="text"
                                    title="Отчество:"
                                    name="patronymic"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.patronymic}
                                    error={errors.patronymic}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />
                                <Input
                                    type="password"
                                    title="Пароль"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    error={errors.password}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    title="Регистрация"
                                    view="primary"
                                >
                                    Добавить
                                </Button>
                            </form>
                        )}
                    </Formik>
                </div>
        </>
    );
}

