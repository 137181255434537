import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export const ModalView = ({visible = false, setVisible, order }) => {
    console.log(order);
    return (
        <>
            <CModal backdrop="static" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader>
                    <CModalTitle>Подробнее о заказе</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>адрес доставки: {order.adress}</p>
                    <p>количество: {order.count} шт.</p>
                    <p>на сумму: {order.amount} руб.</p>
                    <p>телефон: {order.phone}</p>
                    <p>ФИО: {order.name}</p>
                    <p>статус оплаты: {order.status != 2 ? "ожидает оплаты" : "оплачен"}</p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Закрыть
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}
