import { Button } from '../../components/Button';
import React, { useCallback } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { Formik } from 'formik';
import { Input } from '../../components/Input';
import { _URL } from '../../config';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './Product.style.css';

export const ProductsEdit = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { title, price, baseStavka, id} = state;

    const initialValues = {
        title: title,
        price: price,
        baseStavka: baseStavka,
    };

    const onValidate = (values) => {
        const errors = {};

        if(!values.title) {
            errors.title = 'Это поле обязательное'
        }

        if(!values.price) {
            errors.price = 'Это поле обязательное'
        }

        if(!values.baseStavka) {
            errors.baseStavka = 'Это поле обязательное'
        }

        return errors;
    };

    const onSend = useCallback(async (values) => {
        await fetch(`${_URL}/product/productEdit.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'title': values.title,
                    'price': values.price,
                    'baseStavka': values.baseStavka,
                    'id': id
                })
        })
            .then(item => item.json())
            .then(() => {
                navigate('/products');
            })
    }, []);

    return (
        <>
            <div className='dashboard-content-header'>
                <h2>редактировать товар</h2>
                <Button
                    onClick={() => navigate('/products')}
                    icon={<ExitToAppIcon/>}
                >
                    Отмена
                </Button>
            </div>
            <Formik
                initialValues={initialValues}
                validate={(item) => onValidate(item)}
                onSubmit={(values, { setSubmitting }) => {
                    onSend(values);
                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="product_edit__input">
                                <Input
                                    type="text"
                                    title="Наименование товара"
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                    error={errors.title}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />
                                <Input
                                    type="text"
                                    title="Стоимость"
                                    name="price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.price}
                                    error={errors.price}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />

                                <Input
                                    type="text"
                                    title="Базовая ставка"
                                    name="baseStavka"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.baseStavka}
                                    error={errors.baseStavka}
                                    styleTitle="titleInput"
                                    styleInput="inputStyle"
                                />

                            </div>
                            <div className="product_edit__action">
                                <Button
                                    type="submit"
                                    title="Регистрация"
                                    view="primary"
                                    icon={<SaveAltIcon />}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </form>
                )}
            </Formik>
        </>
    );
}
