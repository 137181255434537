import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { useAgents } from '../../hooks/getAgents';
import { ModalView } from './Modal';
import Table from '../../components/Table/Table';
import '../styles.css';

const AgentsLayout = () => {
    const { isLoading, getAgents } = useAgents();
    const navigate = useNavigate();
    const [ordersLine, setOrdersLine] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const formatRowsTable = getAgents.map( (item) => {
            return ({
                fio: `${item.lastName} ${item.name} ${item.patronymic}`,
                ...item,
            })
        });
        setOrdersLine(formatRowsTable);
    }, [getAgents]);


    const onShowDeleteModal = (values) => {
        setSelectedOrder(item => {
            setModalOpen(true);
            return item = values;
        });
    };

    const TableRows = (rows, page) => {
        if (!rows.length) return null;

        return (
            <tbody>
            {rows[page]?.map((item) => (
                <tr key={item.uuid}>
                    <td><span>{item.id}</span></td>
                    <td><span>{item.fio}</span></td>
                    <td><span>{item.login}</span></td>
                    <td>
                        <span>
                            {item.agents.length}
                        </span>
                    </td>
                    <td>
                        <Button onClick={() => onShowDeleteModal(item)}>
                            X
                        </Button>
                    </td>
                </tr>
            ))}
            </tbody>
        );
    };

    const TableHeader = () => {
        return (
            <tr>
                <th>ID</th>
                <th>ФИО</th>
                <th>Логин</th>
                <th>Пользователей шт.</th>
                <th></th>
            </tr>
        );
    }

    return(
        <>
            <div className='dashboard-content-header'>
                <h2>Агенты</h2>
                <Button
                    onClick={() => navigate('addAgents')}
                >
                    Добавить
                </Button>
            </div>

            <Table
                rows = {ordersLine}
                onRows = {TableRows}
                isLoading = {isLoading}
                onThead = {TableHeader}
            />

            <ModalView
                visible={modalOpen}
                setVisible={setModalOpen}
                order={selectedOrder}
            />
        </>
    )
}

export default AgentsLayout;
