import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context';
import { _URL } from '../config';

import { useNavigate } from "react-router-dom";

export const useAuth = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const { setAuth } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(( ) => {
        setIsLoading(true);
        fetch(`${_URL}getAuth.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'token': token,
                })
        })
            .then(item => item.json())
            .then(response => {
                setIsLoading(false);
                if(!response.token) {
                    return navigate('/login');
                }

                setAuth(true);
                navigate('/');

            });
    }, []);

    return {isLoading}
}
