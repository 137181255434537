import QRCode from 'qrcode.react';
import React, { useContext } from 'react';
import { useState } from 'react';
import all_orders from '../Products/tovar';
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from './TabSelector';
import { Button } from '../../components/Button';
import Pdf from "react-to-pdf";
import { AuthContext } from '../../context';
import { useUser } from '../../hooks/getUser';
import { useRewardSize } from '../../hooks/getRewardSize';
import { _URL_PAY } from '../../config';
import { useClipboard } from 'use-clipboard-copy';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DvrIcon from '@mui/icons-material/Dvr';
import './tabs.style.css';
import './main.style.css';

export const Main = () => {
    const user = useUser();
    const [tabs, setTabs] = useState(all_orders);
    const [selectedTab, setSelectedTab] = useTabs(['2']);

   useRewardSize(selectedTab);

    return (
        <>
            <nav className="tabs-line">
                {tabs.map(item => (
                    <TabSelector
                        key={item.id}
                        isActive={selectedTab === item.id}
                        onClick={() => setSelectedTab(item.id)}
                    >
                        {item.product}
                    </TabSelector>
                ))}
            </nav>
            <div className='dashboard-content'>
                <div className='dashboard-content-container'>
                    <div className="p-4">
                        {tabs.map(item => (
                            <TabPanel key={item.id} hidden={selectedTab !== item.id}>
                                <InfoProduct product={item} user={user}/>
                            </TabPanel>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
};

const InfoProduct = ({ product, user}) => {
    const ref = React.createRef();
    const pay = `${_URL_PAY}?user=${user.uuid}&id=${product.id}`;
    const clipboard = useClipboard();

    const { setTextAlert, setShowAlert } = useContext(AuthContext);

    return (
        <>
            <div className="InfoProduct">
                <div className="tab-info-product">
                    {product.id == 1 ? (
                        <>
                            <h2>TEROXOLAX</h2>
                            <h3>ФОРМУЛА ХОРОШЕГО НАСТРОЕНИЯ</h3>
                            <ul>
                                <li>Способствует бодрости</li>
                                <li>Способствует здоровому сну</li>
                                <li>Способствует снижению стресса</li>
                                <li>Способствует хорошему настроению</li>
                                <li>Способствует в поддержании здоровых когнитивных функций</li>
                                <li>Способствует продуктивной работе мозга</li>
                                <li>Способствует уменьшению тревожности</li>
                            </ul>
                        </>
                        ) : null}
                    {product.id == 2 ? (
                            <>
                                <h2>PHENILXEPIN</h2>
                                <h3>для похудения и контроля аппетита</h3>
                                <ul>
                                    <li>Безопасен, не требует назначения врача</li>
                                    <li>Возможно длительное использование</li>
                                    <li>Позволяет справиться с чувством голода</li>
                                    <li>Снижает стресс в процессе изменения пищевого поведения</li>
                                    <li>Увеличивает вашу энергию</li>
                                    <li>Уменьшает тягу к сладкому</li>
                                </ul>
                            </>
                    ) : null}
                </div>
                <div className="tab-settings">
                    <div ref={ref}>
                        <QRCode
                            ref={clipboard.target}
                            value={pay}
                            renderAs="canvas"
                            size={300}
                        />
                    </div>
                </div>
            </div>
            <div className="main_buttons">
                <Pdf
                    targetRef={ref}
                    filename={`qr-${product.product}.pdf`}
                >
                    {({ toPdf }) =>
                        <Button
                        onClick={toPdf}
                        className="download"
                        icon={<CloudDownloadIcon />}
                        >
                            Скачать QR
                        </Button>
                    }
                </Pdf>
                <Button
                    className="download"
                    icon={<FileCopyIcon />}
                    onClick={() => {
                        navigator.clipboard.writeText(pay);
                        setTextAlert("Ссылка скопирована!");
                        setShowAlert(true);
                    }}
                >
                    Скопировать ссылку
                </Button>
                <Button
                    className="download"
                    onClick={() => {
                        window.open(pay, '_blank');
                    }}
                    icon={<DvrIcon />}
                >
                    Открыть форму заказа
                </Button>
            </div>
        </>
    );
}
