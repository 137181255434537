import { useContext, useEffect } from 'react';
import { AuthContext } from '../context';
import { _URL } from '../config';

export const useUser = () => {
    const { setUser, user } = useContext(AuthContext);
    const token = localStorage.getItem("token");

    useEffect(() => {
        fetch(`${_URL}getUser.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'token': token,
                })
        })
            .then(item => item.json())
            .then(response => {
                setUser(response);
            });
    }, []);

    return user;
}
