import { useEffect, useState } from 'react';
import { _URL } from '../config';

export const useUser = () => {
    const token = localStorage.getItem("token");
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([])

    useEffect(() => {
        setIsLoading(true)
        fetch(`${_URL}users/getUsers.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'token': token,
                })
        })
            .then(item => item.json())
            .then(response => {
                setUsers(response);
            }).finally(()=> setIsLoading(false))
    }, []);

    return { isLoading, getUsers: users};
}
