import React, { useState } from 'react';
import './product.style.css';
import { useProfile } from '../../hooks/getProfile';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
    const { isLoading, getProfile } = useProfile();
    console.log(getProfile);

    const navigate = useNavigate();

    return (
        <>
            <div className='dashboard-content-header'>
                <h2>Профиль</h2>
                <Button
                    onClick={
                        () => navigate('/update-profile')
                    }
                >
                    Редактировать
                </Button>
            </div>
            <div className="profile__container">
                    {isLoading ? (<p className="isLoading">Загрузка ...</p>)
                        : (<>
                            <p>Фамилия: <span>{ getProfile?.lastName }</span></p>
                            <p>Имя: <span>{ getProfile?.name }</span></p>
                            <p>Отчество: <span> { getProfile?.patronymic } </span></p>
                            <p>uuid: <span>{ getProfile?.uuid }</span></p>
                            <p>логин: <span>{ getProfile?.login }</span></p>
                            <p>Сейчас на счету: <span>{ getProfile?.currentBalance }</span></p>
                            <p>Всего заработано: <span>{ getProfile?.totalBalance }</span></p>
                            <p>№ счета: <span>{ getProfile?.numberCart }</span></p>
                            <p>Банк: <span>{ getProfile?.bank }</span></p>
                        </>)}
                </div>
        </>
    );
}
