import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Main } from './pages/Main';
import Orders from './pages/Orders';
import DistribRoute from './pages/Distreb';
import DistribLayout from './pages/Distreb/DistribLayout';
import { AddDistrib } from './pages/Distreb/addDistrib';
import Agents from './pages/Agents';
import AgentsLayout from './pages/Agents/AgentsLayout';
import { AddAgents } from './pages/Agents/addAgents';
import ProductsLayout from './pages/Products';
import Products from './pages/Products/Products';
import { ProductsEdit } from './pages/Products/Products.edit';
import Users from './pages/Users';
import { Profile } from './pages/Profile';
import { UpdateProfile } from './pages/Profile/Update.profile';
import React, { useContext } from 'react';
import { AuthContext } from './context';
import { Layout } from './components/Layout';
import { Statistics } from './pages/Statistics';

const PrivateRoute = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();

    return (
        isAuthenticated ? <Layout /> : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export const RoutComponents = () => {
    return (
        <Routes>
            <Route path={"/login"} exact element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path='/' element={<PrivateRoute />}>
                <Route index element={<Main />} />
                <Route path="statistics" element={<Statistics />} />
                <Route path="orders" element={<Orders/>} />
                <Route path="distrib" element={<DistribRoute/>}>
                    <Route index element={<DistribLayout/>} />
                    <Route path="addDisrib" element={<AddDistrib/>} />
                </Route>
                <Route path="agents" element={<Agents/>}>
                    <Route index element={<AgentsLayout/>} />
                    <Route path="addAgents" element={<AddAgents/>} />
                </Route>
                <Route path="products" element={<ProductsLayout />}>
                    <Route index element={<Products />} />
                    <Route path="edit" element={<ProductsEdit />} />
                </Route>
                <Route path="users" element={<Users />} />
                <Route path="profile" element={<Profile />} />
                <Route path="update-profile" element={<UpdateProfile />} />
                <Route path="*" element={<div></div>} />
            </Route>
        </Routes>
    )
}
