import { Outlet } from 'react-router-dom';

export const Layout = () => {
    return (
        <div className='dashboard-content'>
            <div className='dashboard-content-container'>
                <Outlet />
            </div>
        </div>
    );
}
