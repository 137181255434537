import React, {useState, useEffect} from 'react';
import all_orders from './tovar';
import { useProducts } from '../../hooks/getProducts';
import { Button } from '../../components/Button';
import { Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

const Products = () => {
    const {isLoading, getProducts} = useProducts();
    const [productList, setProductList] = useState(all_orders);
    const navigate = useNavigate();

    useEffect(() => setProductList(getProducts), [getProducts]);

    return(
        <>
            <div className='dashboard-content-header'>
                <h2>Продукты</h2>
                <Button>Добавить</Button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Продукт</th>
                        <th>Стоимость шт.</th>
                        <th>Базовая ставка</th>
                        <th></th>
                    </tr>
                </thead>

                {productList.length !== 0 ?
                    <tbody>
                    {productList.map((order, index) => (
                        <tr key={index}>
                            <td><span>{order.title}</span></td>
                            <td><span>{order.price} руб.</span></td>
                            <td><span>{order.baseStavka} руб.</span></td>
                            <td>
                                <Button
                                    onClick={() => navigate('edit',
                                        { state:{
                                            title: order.title,
                                            price: order.price,
                                            baseStavka: order.baseStavka,
                                            id:order.id
                                    }})
                                    }
                                >
                                    <Settings />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    : null}
            </table>
            {
                !productList.length ? (
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>Нет данных</span>
                    </div>
                ) : null
            }
            {isLoading ?
                <div className='dashboard-content-footer'>
                    <span className='empty-table'>Загрузка ...</span>
                </div>
                : null}
        </>
    )
}

export default Products;
