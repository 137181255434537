import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SideBarItem from './sidebar-item';

import LogoutIcon from '../../assets/icons/logout.svg';
import { AuthContext } from '../../context';
import './styles.css';
import { Button } from '../Button';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useResize } from '../../hooks/useResize';

function SideBar ({ menu }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { setAuth, setUser, user } = useContext(AuthContext);
    const [ isOpen, setIsOpen ] = useState(true);

    const [active, setActive] = useState(1);

    const { width } = useResize();

    useEffect(() => {
        if(width <= 1024) {
            setIsOpen(false);
            console.log(width);
            return;
        }
        setIsOpen(true);
    }, [width])

    useEffect(() => {
        menu.forEach(element => {
            if (location.pathname === element.path) {
                setActive(element.id);
            }
        });
    }, [location.pathname])

    const __navigate = (id) => {
        setActive(id);
    }

    const onSwitchVisibleMenu = () => {
        setIsOpen(item => !item);
    }

    return(
        <nav className={`sidebarMenu ${isOpen ? 'openMenu' : 'closeMenu'}`}>
            <div className='sidebar-container'>
                <div className='sidebar-logo-container'>
                    <h3 className="logo">GordonLab</h3>
                    {isOpen ?
                        (
                            <div
                                className="closeMenuIcon"
                                onClick={() => onSwitchVisibleMenu()}
                            >
                                <CloseIcon />
                            </div>
                        )
                        : (
                            <div
                                className="openMenuIcon"
                                onClick={() => onSwitchVisibleMenu()}
                            >
                                <KeyboardArrowRightIcon />
                            </div>
                        )}
                </div>

                <div className='sidebar-container'>
                    <div className='sidebar-items'>
                        {menu.map((item, index) => item.permission.find(value => value === user.id_roles) ? (
                            <div key={index} onClick={() => __navigate(item.id)}>
                                <SideBarItem
                                    active={item.id === active}
                                    item={item}
                                />
                            </div>
                        ) : null)}
                    </div>

                    <div
                        className='sidebar-footer'
                        onClick={() => {
                            localStorage.clear();
                            navigate('/login');
                            setAuth(false);
                            setUser({});
                        }}
                    >
                        <span className='sidebar-item-label'>Выйти</span>
                        <img
                            src={LogoutIcon}
                            alt='icon-logout'
                            className='sidebar-item-icon' />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default SideBar;
