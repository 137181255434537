import React from 'react';
import { Outlet } from 'react-router-dom';
import '../styles.css';

const DistribRoute = () => {
    return (
        <Outlet />
    )
}

export default DistribRoute;
