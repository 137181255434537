import React, { useContext, useState } from 'react';
import Table from '../../components/Table/Table';
import { useUser } from '../../hooks/getUsers';
import { getLinkToOrderForm } from '../../components/linkToOrderForm';
import { ModalView } from './Modal';
import './users.style.css';
import { AuthContext } from '../../context';

function Users () {
    const { isLoading, getUsers } = useUser();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const { setTextAlert, setShowAlert } = useContext(AuthContext);

    const onOpenModal = (userItem) => {
        setSelectedUser((value) => {
            setModalOpen(true);
            return userItem;
        });
    }

    console.log(getUsers);
    const TableRows = (rows, page) => {
        if (!rows.length) return null;

        return (
            <tbody>
            {rows[page]?.map((user) => (
                <tr key={user.uuid}>
                    <td
                        onClick={() => onOpenModal(user)}
                        className="tdActions"
                    >
                        <span>{user.fio}</span>
                    </td>
                    <td><span>{onRoles(user.title)}</span></td>
                    <td><span>{user.login} / {user.password} </span></td>
                    <td>
                        {user?.rewards.map((value) => (
                            <div>
                                <span>{value.title} - {value.value} руб.</span>
                            </div>
                        ))}
                    </td>
                    <td>
                        {user.rewards.map((value) =>
                            <>
                                <span
                                    onClick={() => {
                                        navigator.clipboard.writeText(getLinkToOrderForm(user.uuid, value.merchID));
                                        setTextAlert("Ссылка скопирована!");
                                        setShowAlert(true);
                                    }}
                                    className="linkCopy"
                                >
                                    {value.title}
                                </span>
                                {" "}
                            </>
                        )}
                    </td>
                </tr>
            ))}
            </tbody>
        );
    };

    const onRoles = (role) => {
        switch (role) {
            case 'distributor':
                return "Дистрибьютор";
            case 'admin':
                return "Администратор";
            case 'agent':
                return "Агент"
        }
    }

    const TableHeader = () => {
        return (<tr>
                    <th>ФИО</th>
                    <th>Роль</th>
                    <th>Логин/Пароль</th>
                    <th>Ставка</th>
                    <th>Ссылка на форму</th>
                </tr>);
    }

    return(
        <>
            <div className='dashboard-content-header'>
                <h2>Пользователи</h2>
            </div>
            <Table
                rows = {getUsers}
                onRows = {TableRows}
                isLoading = {isLoading}
                onThead = {TableHeader}
            />
            <ModalView
                visible={modalOpen}
                setVisible={setModalOpen}
                user={selectedUser}
            />
        </>
    )
}

export default Users;
