import React, { useCallback, useContext } from 'react';
import './product.style.css';
import { useProfile } from '../../hooks/getProfile';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/Input';
import { Formik } from 'formik';
import { AuthContext } from '../../context';
import { _URL } from '../../config';
import './update.style.css';
import { PayProfile } from './PayProfile';

export const UpdateProfile = () => {
    const { isLoading, getProfile } = useProfile();

    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    const initialValues = isLoading ? {
        email: getProfile?.login,
        password: getProfile?.password ,
        lastName: getProfile?.lastName,
        firstName: getProfile?.name,
        patronymic: getProfile?.patronymic
    } : {}

    const onValidate = (values) => {
        const errors = {};
        if(!values.email) {
            errors.email = 'Это поле обязательное'
        }

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Введите корректный E-mail'
        }

        if(!values.password) {
            errors.password = 'Это поле обязательное'
        }

        if(!values.lastName) {
            errors.lastName = 'Это поле обязательное'
        }

        if(!values.firstName) {
            errors.firstName = 'Это поле обязательное'
        }

        if(!values.password && values.password.length < 5) {
            errors.password = 'Пароль должен содержать не менее 6 символов'
        }

        return errors;
    };

    const onSend = useCallback(async (values) => {
        await fetch(`${_URL}addDistrib.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'lastName': values.lastName,
                    'firstName': values.firstName,
                    'patronymic': values.patronymic,
                    'email': values.email,
                    'password': values.password,
                    'uuidAgent': values.uuidAgent
                })
        })
            .then(item => item.json())
            .then(response => {
                if(response.uuid) {
                    navigate('/disrib');
                }
            })
    }, []);

    if(isLoading) {
        return (<>Загрузка...</>);
    }

    return (
        <PayProfile />
    );
}
