import { CAlert, CCloseButton } from '@coreui/react';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context';
import './alert.style.css';

export const Alert = ({ isShow = false, text = "" }) => {
    const { setShowAlert } = useContext(AuthContext);

    useEffect(() => {
        setTimeout(() => setShowAlert(false) , 2000)
    }, [isShow]);

    return (
        <CAlert
            color="info"
            visible={isShow}
            className="alertGlobal"
        >
            {text}
            {" "}
            <CCloseButton onClick={ () => setShowAlert (false)}/>
        </CAlert>
    )
}
