import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context';
import { _URL } from '../config';

export const useDistrib = () => {
    const { user } = useContext(AuthContext);
    const [getDistrib, setDistrib] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(( ) => {
        setIsLoading(true);
        fetch(`${_URL}getDistrib.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(
                {
                    'token': user.token,
                    'uuid': user.uuid,
                    'merchId': 1
                })
        })
            .then(item => item.json())
            .then(response => {
                setIsLoading(false);
                setDistrib(response);
            });
    }, []);

    return { isLoading, getDistrib };
}
