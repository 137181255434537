import React, { useState } from 'react';

import SideBar from './components/Sidebar';
import sidebar_menu from './constants/sidebar-menu';
import { AuthContext } from './context';
import { useAuth } from './hooks/getAuth';
import { Alert } from './components/Alert';
import { RoutComponents } from './RoutComponents';
import './App.css';

const App = () => {
    const [isAuthenticated, setAuth] = useState(false);
    const [user, setUser] = useState({});
    const [merch, setMerch] = useState({});
    const [isShowAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState('');
    const {isLoading} =  useAuth();
    const provideValue = {
        isAuthenticated,
        setAuth,
        user,
        setUser,
        merch,
        setMerch,
        isShowAlert,setShowAlert,
        textAlert, setTextAlert
    };

    if(isLoading) {
        return null;
    }

    return(
      <AuthContext.Provider value={provideValue}>
            <div className='dashboard-container'>
                {isAuthenticated && user.id ? <SideBar menu={sidebar_menu} /> : null }
                <div className='dashboard-body'>
                    <RoutComponents />
                </div>
            </div>
            <Alert isShow={isShowAlert} text={textAlert}/>
      </AuthContext.Provider>
  )
}

export default App;
